import { createSlice } from '@reduxjs/toolkit'
import { loggedOut } from '../Auth/authSlice'
import queryS from '../../services/helpers/query'
import removeEmpty from '../../services/helpers/removeEmpty'

const articlesSlice = createSlice({
  name: 'articles',
  initialState: {
    articles: {},
    article: {},
    latestArticles: [],
  },
  reducers: {
    articlesAdded(state, { payload: { queryString, articles } }) {
      state.articles[queryString] = articles
    },
    articleAdded(state, { payload: { queryString, article } }) {
      state.article[queryString] = article
    },
    latestArticlesAdded(state, { payload: { latestArticles } }) {
      state.latestArticles = latestArticles
    },
  },
  extraReducers: {
    [loggedOut]: (state) => {
      state.articles = {}
      state.article = {}
      state.latestArticles = []
    },
  },
})

const getArticles = (state) => (query) => {
  return state.articles.articles[queryS(query)]
}

const getArticle = (state) => (query) => {
  return state.articles.article[queryS(query)]
}

const getLatestArticles = (state) => {
  return state.articles.latestArticles
}

const fetchArticles = (query = {}) => async (dispatch, getState, { api }) => {
  const cleanQuery = removeEmpty({ per_page: 15, ...query })
  const queryString = queryS(query)
  const cachedArticles = getArticles(getState())(query)

  if (cachedArticles) {
    return cachedArticles
  }

  const response = await api.articles.list(cleanQuery)
  const articles = response.data.news

  dispatch(articlesSlice.actions.articlesAdded({ queryString, articles }))

  return articles
}

const fetchArticle = (id) => async (dispatch, getState, { api }) => {
  const queryString = queryS({ id })
  const cachedArticle = getArticle(getState())({ id })

  if (cachedArticle) {
    return cachedArticle
  }

  const response = await api.articles.show({
    per_page: 2,
    id,
  })
  const article = response.data.new
  const latestArticles = response.data.other_news.data

  dispatch(articlesSlice.actions.articleAdded({ queryString, article }))
  dispatch(articlesSlice.actions.latestArticlesAdded({ latestArticles }))

  return article
}

export default {
  getLatestArticles,
  getArticles,
  getArticle,
  fetchArticle,
  fetchArticles,
  reducer: articlesSlice.reducer,
}
